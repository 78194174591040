export const CUSTOM_COMPONENT_ENRICHMENT = {
    'gcs-privateKey': {
      'validators': [ (name, value, input) => {              
        if( typeof value === 'object' ) {                
          if ( !value.type.match('application/json') ) {
            return 'File mime type needs to be application/json';
          }
          if( value.size > 10240 ) {
            return 'File size exceeds 10 Kb';
          }              
        }            
      } ],
      'extractor': ( value ) => {
        let jsonContent;
    
        try {
          jsonContent = JSON.parse( value );                            
        } catch (e) {              
          throw new Error('The file is not json');              
        } 
    
        if( !jsonContent.hasOwnProperty('private_key') ) {
          throw new Error( 'This does not look like GCP key file, no private_key is found.' );
        }
    
        return jsonContent.private_key;            
      },
      'accept': '.json'
    }
};