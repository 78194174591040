import { useState } from 'react';

import {FormInputComponentDecorator} from '.';

export function FileInputComponent({error, controller, fieldObject, ...opts}) {

    const [uploaded, setUploaded] = useState( fieldObject.defaultValue ? true : false );

    const revealFileUpload = () => {        
        setUploaded(false);
    }
    
    if( uploaded ) {
        return <div className="fileUploaded"><p>uploaded <a href="#" onClick={revealFileUpload}>click here to upload a new file</a></p><input type="hidden" name={fieldObject.uniqueName} value={fieldObject.defaultValue}/></div>
    }
    
    return <FormInputComponentDecorator error={error} fieldObject={fieldObject}>
        <input 
            type="file" 
            className={'form-control ' + (error[fieldObject.uniqueName] ? 'is-invalid' : '')} 
            name={fieldObject.uniqueName}                                
            onChange={controller.handleChange} 
            onBlur={controller.handleBlur} {...opts} 
        />    
    </FormInputComponentDecorator>
}