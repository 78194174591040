import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// '../../' is the path to src/
import config from '../../config';

export function LoginPage() {

    const navigate = useNavigate();     
    const [input, setInput] = useState({
        username: '', 
        password: ''        
    });    
    const [error, setError] = useState( {} );     
    const [loggingIn, setLoggingIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    function handleChange(e) {
        const { name, value } = e.target;                
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
    }

    const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
            const stateObj = {  ...prev, [name]: "" };
            delete stateObj[name]; // helps us to determine if there are any errors
            return { ...stateObj ,..._validateInput( name, value ) }            
        });        
    }

    function handleSubmit(e) {
        e.preventDefault();

        if( !_isFormValid() ) return;

        setLoggingIn(true);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };

        fetch(`${config.apiUrl}/api/users/authenticate`, requestOptions )
            .then( handleResponse )
            .then(
                user => {                         
                    setLoggingIn(false);                        
                    sessionStorage.setItem( 'user', JSON.stringify(user) );
                    window.dispatchEvent( new Event('storage') ); // dispath a local storage change notification

                    let destinationUri = '/profile';
                    
                    if(user.connections) {
                        const sources = new Set( user.connections.split(",") );
                        if( sources.has('source') && sources.has('target') ) destinationUri = '/profile/dashboard';
                        else if( sources.has('source') ) destinationUri = '/profile/configureSource';
                        else if( sources.has('target') ) destinationUri = '/profile/configureTarget';                        
                    }
                                        
                    navigate( destinationUri );
                },
                error => { setErrorMessage( error ); setLoggingIn(false); }
            );
    }

    function _isFormValid() {        
        let combinedState = {};

        for (let key in input) {                        
            combinedState = { ...combinedState, ..._validateInput( key, input[key] ) };
        }       

        setError(prev => {
            return {  ...prev, ...combinedState }
        });

        return Object.keys(combinedState).length === 0;
    }

    function _validateInput( name, value ) {
        const stateObj = {};

        switch (name) {
  
            case "username":
              if (!value) {                
                stateObj[name] = "Please enter Username.";
              }
              break;
     
            case "password":
              if (!value) { 
                stateObj[name] = "Please enter Password.";
              }                
              break;

            default:
              break;
        }
        return stateObj;
    }

    function handleResponse(response) {

        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 response returned from api
                    //logout();
                    //window.location.reload(true);
                }
    
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
           
            return data;
        });
    }

    return (
        <div className="col-md-6 col-md-offset-3">
            <h2>Login</h2>
            {errorMessage && (
                <p className="alert alert-danger" role="alert"> {errorMessage} </p>
            )}
            <form name="form" onSubmit={handleSubmit}>
                <div className={'mb-3' + (error.username ? ' has-error' : '')}>
                    <label htmlFor="username">Username</label>
                    <input type="text" className={'form-control ' + (error.username ? 'is-invalid' : '')} name="username" value={input.username} onChange={handleChange} onBlur={validateInput} />
                    {error.username && <span className='invalid-feedback'>{error.username}</span>}
                </div>
                <div className={'mb-3' + (error.password ? ' has-error' : '')}>
                    <label htmlFor="password">Password</label>
                    <input type="password" className={'form-control ' + (error.password ? 'is-invalid' : '')} name="password" value={input.password} onChange={handleChange} onBlur={validateInput} />
                    {error.password && <span className='invalid-feedback'>{error.password}</span>}
                </div>                
                <button className="btn btn-primary">Login</button>
                {loggingIn &&
                    <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="loading"/>
                }
                <Link to="/registration" className="btn btn-link">Register</Link>                
            </form>
        </div>
    );
}