import { useState, useEffect } from 'react';

// '../../' is the path to src/
import { FormComponent } from '../../Components/FormComponent'; 
import Field from '../../Models/FieldModel';
import config from '../../config';
import { authHeader } from '../../Helpers/auth-header';

import { Loading } from '../../Components';

import { toast } from "react-toastify";

const certificateValidators = [ (name, value, input) => {        
	if( typeof value === 'object' ) {                
		if ( !value.type.match('application/x-x509-ca-cert') ) {
			return 'File mime type needs to be application/x-x509-ca-cert';
		}
		if( value.size > 10240 ) {
			return 'File size exceeds 10 Kb';
		}              
	}            
}];

const certificateExtractor = ( value ) => {
	const BEGIN_CERT = '-----BEGIN CERTIFICATE-----';
	const END_CERT = '-----END CERTIFICATE-----';
	let beginCertIndex = value.indexOf(BEGIN_CERT);
	let endCertIndex = value.indexOf(END_CERT);
	if(beginCertIndex < 0 || endCertIndex < 0) {
		throw new Error( `This does not look like a valid .crt file. The file should contain ${BEGIN_CERT} and ${END_CERT}` );
	}
	return value.substring(beginCertIndex, endCertIndex + END_CERT.length);	
};
  

export function ConfigureSourcePage() {

	function validateMyDomainUrlFormat (name, value, input) {

		if(!value) return;

		value = value.toLowerCase();
		let isValid = (value.startsWith('https://') && value.endsWith('.my.salesforce.com') && !value.includes(' ') && !value.includes('..'));

		if( !isValid ) {
			return 'My Domain URL must be a valid URL that starts with \'https://\' and ends with \'.my.salesforce.com\'';
		}
	}

	const [isLoading, setLoading] = useState(true);
	const [fields, setFields] = useState(null);
	const [connection, setConnection] = useState({loaded: false});

	function executeGetRequest() {
		setLoading(true);
		
		fetch(
			`${config.apiUrl}/api/connections/?direction=source`, 
			{method: 'GET', headers: { 'Accept': 'application/json', ...authHeader() }}
		)
		.then((response) => {
			if(response.status === 200) {
				return response.json();
			}
			response.json().then( (json) => {				
				setLoading(false);								
				toast.error(json.message);	
				initialyzeFields(response.connection); // displaying empty form to accept new connection params			
			})
			.catch( () => { setLoading(false); } );
		})
		.then((response) => {
			setLoading(false);						
			initialyzeFields(response.connection);			
			if( response.connection) setConnection(response.connection); // @TODO: we might not need this			
		})
		.catch( () => { setLoading(false); } );
		
	}

	function initialyzeFields(connection) {

		const fields = [
			new Field({
				uniqueName: 'certificate',
				label: 'Certificate',
				attributeType: 'file',
				isRequired: true,
				isReadOnly: false,
				defaultValue: connection?.cert ? connection.cert : '',
				validators: certificateValidators,
				extractor: certificateExtractor,
				accept: '.crt, .cer, .pem', 
				helpText: 'Accepts x509 certificate in PEM format (.cer, .crt, .pem file extensions)'
			}),			
			new Field({
				uniqueName: 'base_url',
				label: 'My Domain URL',
				attributeType: 'text',
				isRequired: true,
				isReadOnly: false,
				validators: [validateMyDomainUrlFormat], 
				defaultValue: connection?.config?.base_url ? connection.config.base_url.replace('/services/oauth2/token', '') : ''
			}),
			new Field({
				uniqueName: 'client_id',
				label: 'Client Id',
				attributeType: 'text',
				isRequired: true,
				isReadOnly: false,
				defaultValue: connection?.config?.client_id ? connection.config.client_id : ''
			}),
			new Field({
				uniqueName: 'client_secret',
				label: 'Client Secret',
				attributeType: 'password',
				isRequired: true,
				isReadOnly: false,
				defaultValue: connection?.config?.client_secret ? connection.config.client_secret : ''
			})
			
		];

		setFields(fields);
	}

	useEffect(() => {      
		executeGetRequest();
	}, []);
		
	function saveConnection(input) {
		const sfConnection = {
			type: 'salesforce',				
			...input
		}

		if (connection?.id) {
			sfConnection.id = connection.id;
		}
		const createRequestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json', ...authHeader() },
			body: JSON.stringify(sfConnection)
		};
		return fetch(`${config.apiUrl}/api/connections/source`, createRequestOptions);
	}
	

	function handleSubmit(input) {

		const handleResponseJsonError = function( jsonPromise, rejectFunc ) {
			jsonPromise
			.then( jsonError => {
				rejectFunc( jsonError.errorMessage );
			}).catch( e => { rejectFunc(e); } );	
		}

		return new Promise( (resolve, reject) => {

			saveConnection(input)
				.then( response => {	
					if( response.ok ) {								
						resolve();		
						toast.success('Source connection has been successfully saved');
					}
					else {										
						handleResponseJsonError( response.json(), reject );
					}									
				}).catch(e => { reject(e) });
		});			
	}
	
	return (
			<div>
				{isLoading && <Loading/>}					
				{ fields && <FormComponent 
					title="Salesforce connection" 
					handleSubmit={handleSubmit} 
					fields={fields} 
					navigateToOnComplete={'/profile'} 
					navigateToOnCancel={'/profile'}
				/> }
			</div>
		);
}