/**
 * @description This is a copy-paste of the AttributeModel class from @arcane\filecloudcore. They must stay in sync, because the
 * implementation of FormComponent relies on it (via duck typing: https://en.wikipedia.org/wiki/Duck_typing).
**/
export default class FieldModel {    
    #uniqueName;
    #label;
    #attributeType;
    #isRequired;
    #isReadOnly;
    #defaultValue;   
    #validators;
    #extractor;
    #accept;
    #helpText;
        
    constructor ( {uniqueName, label, attributeType, isRequired, isReadOnly, defaultValue, validators, extractor, accept, helpText} ) {
        this.#uniqueName = uniqueName;
        this.#label = label;
        this.#attributeType = attributeType;
        this.#isRequired = isRequired;
        this.#isReadOnly = isReadOnly;
        this.#defaultValue = defaultValue;
        this.#validators = validators;
        this.#extractor = extractor;
        this.#accept = accept;
        this.#helpText = helpText;
    }

    get uniqueName() { 
        return this.#uniqueName; 
    }

    set uniqueName(value) {
        this.#uniqueName = value;
    }

    get label() { 
        return this.#label; 
    }

    get attributeType() { 
        return this.#attributeType; 
    }

    get isRequired() { 
        return this.#isRequired; 
    }

    get isReadOnly() { 
        return this.#isReadOnly; 
    }

    get defaultValue() { 
        return this.#defaultValue; 
    }

    set defaultValue(value) {
        this.#defaultValue = value;
    }

    get validators() { 
        return this.#validators; 
    }

    get extractor() {
        return this.#extractor;
    }

    get accept() {
        return this.#accept;
    }

    get helpText() {
        return this.#helpText;
    }
}