import { useState, useEffect } from 'react';
import Select from 'react-select';

// '../../' is the path to src/
import { FormComponent } from '../../Components/FormComponent'; 
import config from '../../config';
import { authHeader } from '../../Helpers/auth-header';

import { Loading } from '../../Components';
import { toast } from "react-toastify";

import {CUSTOM_COMPONENT_ENRICHMENT} from './CustomComponentEnrichment';

const ADAPTER_LABELS = {
  'awsS3': 'Amazon S3', 
  'local': 'Local File Storage', 
  'gcs': 'Google Cloud Storage'
};

export function ConfigureTargetPage() {
    const [fields, setFields] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [adapterTypeOptions, setAdapterTypeOptions] = useState(null);
    const [selectedAdapterType, setSelectedAdapterType] = useState(null);
    const [isConnectionPersisted, setIsConnectionPersisted] = useState(false);

    function enrichPayload( type, payload ) {

      const newPayload = [];

      for( let item of payload ) {
        const attributeFQN = `${type}-${item.uniqueName}`;

        if( CUSTOM_COMPONENT_ENRICHMENT.hasOwnProperty( attributeFQN ) ) {
          newPayload.push( {...item, ...CUSTOM_COMPONENT_ENRICHMENT[attributeFQN] } );
        }
        else newPayload.push(item);
      }

      return newPayload;
    }

    function _translateLabel(val) {
      return ADAPTER_LABELS.hasOwnProperty( val ) ? ADAPTER_LABELS[val] : val;
    }

    function executeGetRequest( queryParams ) {
      setLoading(true);
      fetch(
        `${config.apiUrl}/api/connections/target` + ( queryParams ? '?' + new URLSearchParams( queryParams ) : '' ), 
        { method: 'GET', headers: { 'Accept': 'application/json', ...authHeader() } }
      )
      .then(res => res.json())
      .then(data => {                
          setLoading(false);
          if( data.availableAdapters ) {
            setAdapterTypeOptions( data.availableAdapters.map( (val) => { return { value: val, label: _translateLabel(val) } } ) );                  
          }

          if( data.configMetadata ) {
            const { type, configMetadata } = data;
            setSelectedAdapterType( {  label: _translateLabel(type), value: type } );
            setFields( enrichPayload( type, configMetadata ) );
          }

          setIsConnectionPersisted( data.isPersisted );
      } )
      .catch( () => { setLoading(false); } );
    }

    useEffect(() => {      
      executeGetRequest();
    }, []);

   
    async function handleSubmit( input ) {
      

      const testRequestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader() },
        body: JSON.stringify( { type: selectedAdapterType.value, attributes: input } )
      };

      const response = await fetch(`${config.apiUrl}/api/connections/target`, testRequestOptions );
      const json = await response.json();
      if( !response.ok ) {
        return json.errorMessage;               
      }

      toast.success('Target connection has been successfully saved');
    }

    function handleChange( evt ) {
      setSelectedAdapterType( evt );
      setFields( null ); // erasing fields before repopulating them
      executeGetRequest( { type: evt.value } );
    }

    return (      
        <div>
          { isLoading && <Loading/> }

          {adapterTypeOptions && <Select 
            options={adapterTypeOptions} 
            placeholder="Choose target storage adapter" 
            onChange={handleChange} 
            defaultValue={selectedAdapterType} 
            isDisabled={isConnectionPersisted}
          />}
          
          {fields && <FormComponent 
            title="Connection" 
            handleSubmit={handleSubmit} 
            fields={fields} 
            navigateToOnComplete={'/profile'}       
            navigateToOnCancel={'/profile'}
          />}
          
        </div>        
      );
}