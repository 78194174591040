import { useState, useEffect } from 'react';

import { ConnectionStatusCardComponent } from "../../Components/ConnectionStatusCardComponent";
import { ServiceStatusCardComponent } from "../../Components/ServiceStatusCardComponent";
import { DailyStatsCardComponent } from "../../Components/DailyStatsCardComponent";
import { TotalStatsCardComponent } from "../../Components/TotalStatsCardComponent";
import { ProvisioningStatusCardComponent } from '../../Components/ProvisioningStatusCardComponent';

import config from '../../config';

const uiServices = config.frontendControlledServices;

export function DashboardPage() {

    const [hasSubscription, setSubscription] = useState(false);

    useEffect(() => {      
        const uSessionData = sessionStorage.getItem('user');
        if(uSessionData) setSubscription( JSON.parse(uSessionData).has_subscription );  
    }, []);
    
    return (
        <>
            <h1>Dashboard</h1>           
            { !hasSubscription && <div className="alert alert-warning d-flex align-items-center" role="alert">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
                </svg>
                <div>
                    The current user doesn't have any subsription. File Upload and Download services are anavaialble. Please contact support for additional details.
                </div>
            </div> } 
            <div className="row mb-3">
                <ConnectionStatusCardComponent direction="source" />
                <ConnectionStatusCardComponent direction="target" />
            </div>
        {process.env.NODE_ENV === 'production' && uiServices.includes('EventSubscriberPrototype') ? (
            <div className="row mb-3">
                <ServiceStatusCardComponent uniqueName="EventSubscriberPrototype" label="Event Subscriber" />
            </div>
        ) : (<>
            { ( uiServices.includes('EventSubscriberPrototype') || uiServices.includes('FileWriterPrototype') )  && <div className="row mb-3">
                { uiServices.includes('EventSubscriberPrototype') && <ServiceStatusCardComponent uniqueName="EventSubscriberPrototype" label="Event Subscriber" /> }
                { uiServices.includes('FileWriterPrototype') && <ServiceStatusCardComponent uniqueName="FileWriterPrototype" label="File Writer" /> }
            </div> }
            { uiServices.includes('FileReaderPrototype') && <div className="row mb-3">
                <ServiceStatusCardComponent uniqueName="FileReaderPrototype" label="File Reader" />
            </div> }
        </>)}    
            <div className="row mb-3">
                <TotalStatsCardComponent />
                <DailyStatsCardComponent />
            </div>
            <div className="row mb-3">
                <ProvisioningStatusCardComponent />
            </div>
        </>
      );
}