// '../../' is the path to src/
import config from '../../config';
import { FormComponent } from '../../Components/FormComponent'; 
import Field from '../../Models/FieldModel';
import handleResponse from '../../Components/FormComponent/FormComponentHelper';
import { useState } from 'react';

import { toast } from "react-toastify";

export function RegistrationPage() {

    function confirmPasswordMatchValidation(name, value, input) {
        if(input.password !== input.confirmPassword) {
            return 'Password and Confirm Password do not match.';
        }
    }

    let fields = [
      new Field({uniqueName: 'firstName', label: 'First Name', attributeType: 'text', isRequired: true, isReadOnly: false}),
      new Field({uniqueName: 'lastName', label: 'Last Name', attributeType: 'text', isRequired: true, isReadOnly: false}),
      new Field({uniqueName: 'username', label: 'Username', attributeType: 'text', isRequired: true, isReadOnly: false}),
      new Field({uniqueName: 'password', label: 'Password', attributeType: 'password', isRequired: true, isReadOnly: false}),
      new Field({uniqueName: 'confirmPassword', label: 'Confirm Password', attributeType: 'password', isRequired: true, isReadOnly: false, validators: [confirmPasswordMatchValidation]}),
      new Field({uniqueName: 'token', attributeType: 'hidden', isRequired: false, isReadOnly: false })
    ];
    
    function handleSubmit(input) {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(input)
        };

        return fetch(`${config.apiUrl}/api/users/register`, requestOptions )
        .then((response) => {             
            return handleResponse(response) 
        })
        .then(
            (response) => {
                toast.success('You have been successfully registered');
                return;
            },
            (error) => { 
                return error;
            }
        );        
    }

    return (
        <FormComponent 
            title="Registration" 
            handleSubmit={handleSubmit} 
            fields={fields} 
            navigateToOnComplete={'/profile'} 
            withReCaptcha={true}
        />
      );
}