import { useState, useEffect } from 'react';
import { LoadingSpinner } from '../../Components';
import { authHeader } from '../../Helpers/auth-header';

import config from '../../config';

export function DailyStatsCardComponent() {

    const [isLoading, setIsLoading] = useState(false);
    const [dailyStats, setDailyStats] = useState([]);

    function executeGetRequest( ) {
        setIsLoading(true);
        fetch(
          `${config.apiUrl}/api/stats/daily`, 
          { method: 'GET', headers: { 'Accept': 'application/json', ...authHeader() } }
        )
        .then(res => res.json())
        .then(data => {       
            setDailyStats(data);
            setIsLoading(false);
        } )
        .catch( () => { setIsLoading(false); } );
    }

    useEffect(() => {      
        executeGetRequest();
    }, []);  

    return (<>        
            <div className="col-md-6 col-md-offset-3">
            { isLoading 
                ? <LoadingSpinner className="d-flex justify-content-center"/> 
                : <div className="card">                
                    <div className="card-body">
                        <h5 className="card-title">Daily viewed files' stats (last 30 days)</h5>       
                        { dailyStats.length > 0 
                            ? <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Num of views</th>
                                        <th scope="col">Volume</th>                            
                                    </tr>
                                </thead>
                                <tbody>
                                    { dailyStats.map( (row, index) => {
                                        return (
                                        <tr key={index} >
                                            <th scope="row">{ (new Date(row.date)).toLocaleDateString() }</th>
                                            <td>{row.num_of_views}</td>
                                            <td>{Math.round(row.volume / 1024).toLocaleString()} KB</td>                            
                                        </tr>
                                        );
                                    } )}                                
                                </tbody>
                              </table>
                            : <div className="card-text">No historical data found</div>
                        }                                     
                    </div>
                </div>                
            }        
        </div>
    </>        
    );
}