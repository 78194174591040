module.exports = {
   apiUrl: '', // same host and port as is served from the backend   
   
   /**
      * Allows specific services to be controlled from UI. 
      * Supported services: ['EventSubscriberPrototype', 'FileWriterPrototype', 'FileReaderPrototype'] 
   */        
   frontendControlledServices: [], 

   recaptchaKey: "6Ld1hwgqAAAAAJeE8YaFgBLL92wpV-oBj8jYEH05"
}